import React from "react";

export const Loading = ({ active }: { active: boolean }) => {
  if (active) {
    return <div className="loaderContainer"><div className="loader"></div></div>;
  }
  return null;
}

export default Loading;
