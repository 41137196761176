import React, {useState} from "react";
import "../styles/searchStatus.css";
import StatusLegend from "./StatusLegend";
import SearchResponse, {ISearchResponse} from "./SearchResponse";
import {NOT_FOUND_STATUS} from "./Status";
import Loading from "./Loading";

interface ISearchStatusProps {
  response?: ISearchResponse;
  search_reference: (number: string) => void;
  isLoading: boolean;
}

export const SearchStatus = ({ response, search_reference, isLoading }: ISearchStatusProps) => {
  const [ referenceNumber, setReferenceNumber ] = useState('');
  const onSearch = (e: any) => {
    e.preventDefault();
    search_reference(referenceNumber);
  };
  const onChangeReference = (e: React.ChangeEvent<HTMLInputElement>): void => setReferenceNumber(e.target.value);

  const responseSection = response && <SearchResponse response={response}/>;
  const legendSection = response && response.status !== NOT_FOUND_STATUS && <StatusLegend />;
  return (
    <section className="search_status">
      <section className="status_form">
        <header>
          <form onSubmit={onSearch}>
            <input type="text" placeholder="Reference Number" value={referenceNumber} onChange={onChangeReference} />
            <button type="submit" disabled={isLoading}>Search</button>
            <Loading active={isLoading} />
          </form>
        </header>
        {responseSection}
        {legendSection}
      </section>
    </section>
  );
};

export default SearchStatus;
