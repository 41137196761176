import React, { useState } from "react";
import SearchStatus from "./SearchStatus";
import { ISearchResponse } from "./SearchResponse";
import { searchReference } from "../services/SearchStatusService";

export const SearchStatusContainer = () => {
  const [response, setResponse] = useState<ISearchResponse | undefined>(
    undefined
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const onSearchReference = async (referenceId: string) => {
    try {
      setLoading(true);
      const response: ISearchResponse = await searchReference(referenceId);
      setResponse(response);
    } finally {
      setLoading(false);
    }
  };
  return (
    <SearchStatus response={response} search_reference={onSearchReference} isLoading={isLoading} />
  );
};

export default SearchStatusContainer;
