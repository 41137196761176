import React from "react";

export interface IStatus {
  name: string;
  description: string;
  id: number;
}

export const NOT_FOUND_STATUS = "No results have been found";

export const Statuses: Array<IStatus> = [
  { name: "In Progress", description:"NEPC gathering information and preparing materials", id: 3 },
  { name: "Completed", description: "Search is no longer in process", id: 4 },
  { name: "Canceled", description: "Search has been canceled", id: 5 },
  { name: "Presentation Process", description: "Client is in process of reviewing candidates", id: 6 },
  { name: "Hold", description: "Search has been placed on Hold", id: 7 },
  { name: NOT_FOUND_STATUS, description: NOT_FOUND_STATUS, id: 404 }
];

interface IStatusProps {
  value: string;
}
export const Status = ({ value }: IStatusProps): JSX.Element => {
  const status_css = value.toLowerCase().replace(" ", "_");
  return <span className={status_css}>{value}</span>;
};

export default Status;
