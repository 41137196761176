import React from "react";
import Status, { IStatus, Statuses, NOT_FOUND_STATUS } from "./Status";

export const StatusLegend = () => {
  const items = Statuses.filter((status: IStatus) => {
    return status.name !== NOT_FOUND_STATUS;
  }).map((status: IStatus) => {
    return (
      <li key={status.id}>
        <strong><Status value={status.name} /></strong>
        <p>{status.description}</p>
      </li>
    );
  });
  return (
    <aside className="status_legend card">
      <h2>Status Key</h2>
      <section>
        <ul>{items}</ul>
      </section>
    </aside>
  );
};
export default StatusLegend;
