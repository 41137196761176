import React from "react";
import "../styles/App.css";

import SearchStatusContainer from "./SearchStatusContainer";

const App: React.FC = () => {
  const isInIframe = () => window.self !== window.top;
  return (
    <div className="App">
      {Boolean(!isInIframe()) && (
        window.location.replace("https://www.nepc.com/institutional/investment-managers/")
      )}
      {Boolean(isInIframe()) && (
        <SearchStatusContainer />
      )}
    </div>
  );
};

export default App;
