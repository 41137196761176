import React from "react";
import Status, {NOT_FOUND_STATUS} from "./Status";

export interface ISearchResponse {
  reference: string;
  status: string;
  consultant: string;
  csa: string;
}

export const notFound: ISearchResponse = {
  reference: '',
  status: NOT_FOUND_STATUS,
  consultant: '',
  csa: '',
}

interface ISearchResponseProps {
  response: ISearchResponse
}
export const SearchResponse = ({ response }: ISearchResponseProps): JSX.Element => {
  return (
    <section className="status_result card">
      <h2>Reference Number Status</h2>
      <div className="status">
        <h3>
          {response.reference} <Status value={response.status} />
        </h3>
        <div>
          {response.consultant && (
          <p>
            <strong>Consultant:</strong> {response.consultant}
          </p>
          )}
          {response.csa && (
          <p>
            <strong>Consulting Analyst:</strong> {response.csa}
          </p>
          )}
        </div>
      </div>
    </section>
  );
}

export default SearchResponse;
