import {ISearchResponse, notFound} from "../components/SearchResponse";
import {Statuses, NOT_FOUND_STATUS} from "../components/Status";

export const convertStatus = (status: number) => {
  for (let i = 0; i < Statuses.length; i++) {
    const stt = Statuses[i];
    if (stt.id === status) {
      return stt.name;
    }
  }
  return NOT_FOUND_STATUS;
}

export const searchReference = (reference: string) => fetch('/api/v1/searchstatus?referenceId=' + reference)
  .then(response => response.json())
  .then((data: any): ISearchResponse => {
    return {
      reference: data['requestId'],
      status: convertStatus(data['requestStatus']),
      consultant: data['consultantName'],
      csa: data['supportAnalystName'],
    };
  })
  .catch(() => {
    return {...notFound};
  });
